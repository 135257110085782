"use client";
import Link from "next/link";
import { useState, useEffect } from "react";

export const Button = ({
  aria,
  children,
  url,
  innerClasses,
  classes,
  ref,
  styles,
}) => {
  const [isInternal, setIsInternal] = useState(true);

  useEffect(() => {
    const isSameDomain =
      new URL(url, window.location.origin).host === window.location.host;
    setIsInternal(isSameDomain);
  }, [url]);

  return (
    <>
      {isInternal ? (
        <Link
          aria-label={aria ?? children}
          scroll={true}
          className={"GlobalButton mt-[50px] lg:py-[10px] " + classes}
          href={url}
        >
          <div
            style={styles}
            className={
              "buttonHover  flex justify-center items-center h-[100%] relative rounded-full bg-[#1757A3] font-semibold text-[18px] px-12 py-3.5 text-white " +
              innerClasses
            }
          >
            {children}
          </div>
        </Link>
      ) : (
        <Link
          aria-label={aria ?? children}
          scroll={true}
          className={"GlobalButton mt-[50px] lg:py-[10px] " + classes}
          href={url}
        >
          <div
            style={styles}
            className={`buttonHover h-[100%] relative rounded-full bg-[#1757A3] font-semibold text-[18px] px-12 py-3.5 text-white ${innerClasses}`}
          >
            {children}
          </div>
        </Link>
      )}
    </>
  );
};
